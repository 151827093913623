<template>
    <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
        <div>
            <div v-if="appData">
                <claim-summary :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly" v-on="$listeners" />

                <claim-policy-summary :app-data="appData" :ref-data="refData" :is-read-only="isReadOnly"
                    v-on="$listeners" />

                <claim-alert :app-data="appData" :is-read-only="isReadOnly" v-on="$listeners" />

                <claim-follow-up :app-data="appData" :is-read-only="isReadOnly" />

                <instructions :instructions-text="instructions" />

                <assessment-details :app-data="appData" :is-read-only="isReadOnly" v-on="$listeners" />

                <claim-contacts title="Claim Contacts" :all-contacts="refData.allContacts" :app-data="appData"
                    :ref-data="refData" :is-read-only="isReadOnly" :hide-primary-column="true" v-on="$listeners"
                    :filter-contacts="false" />

                <claim-damages :app-data="appData" :is-read-only="isReadOnly" />

                <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
                    <h4 class="font-weight-bold text-dark">
                        <i class="fas fa-dollar-sign fa-md text-primary"></i>
                        <span class="ml-2" style="vertical-align: center">Claim Reserve</span>
                    </h4>
                    <div class="mt-4">
                        <label class="form-label">Please update the Claim Reserve amount</label>
                        <ValidationProvider rules="" name="claimReserve" v-slot="{ errors }">
                            <div class="d-flex align-items-center">
                                <div class="input-group" style="width: 25%">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <input type="text" class="form-control" v-model="formattedClaimReserve"
                                        :disabled="isReadOnly" @blur="formatOnBlur" />
                                </div>
                                <button v-if="!isReadOnly" type="button" class="btn btn-primary ml-3"
                                    @click="saveClaimReserve">
                                    Save
                                </button>

                                <b-alert v-if="showSaveConfirmation" variant="success" class="mb-0 ml-8"
                                    style="padding: 0.5rem 1rem;" show>
                                    Saved successfully
                                </b-alert>
                            </div>
                            <span v-if="errors.length" class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </b-card>

                <claim-settlements :app-data="appData" :is-read-only="isReadOnly"
                    @update:claimSettlements="updateClaimSettlements" />
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import ClaimSummary from "../components/ClaimSummary";
import ClaimPolicySummary from "../components/ClaimPolicySummary";
import ClaimAlert from "../components/ClaimAlert";
import ClaimFollowUp from "../components/ClaimFollowUp.vue";
import Instructions from "../../../common/Instructions";
import AssessmentDetails from "./AssessmentDetails.vue";
import ClaimContacts from "../components/ClaimContacts.vue";
import ClaimDamages from "../components/ClaimDamages.vue";
import ClaimSettlements from "../components/ClaimSettlements.vue";

export default {
    name: "ClaimMgmtPage",

    components: {
        ClaimSummary,
        ClaimPolicySummary,
        ClaimAlert,
        ClaimDamages,
        ClaimFollowUp,
        ClaimSettlements,
        Instructions,
        ClaimContacts,
        AssessmentDetails,
    },

    props: {
        appData: Object,
        refData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {
            showSaveConfirmation: false
        }
    },

    methods: {
        formatOnBlur() {
            // Ensure proper formatting when the field loses focus
            if (this.appData.claim.K_ClaimReserve) {
                this.appData.claim.K_ClaimReserve = Number(this.appData.claim.K_ClaimReserve);
            }
        },

        updateClaimSettlements(newSettlements) {
            this.$set(this.appData, 'claimSettlements', newSettlements);
            this.$emit("updateWizardAppDataString");
        },

        saveClaimReserve() {
            // Create data object for the update
            const claimData = {
                K_ClaimReserve: this.appData.claim.K_ClaimReserve
            };

            // Call the claimDataService to update
            this.$claimDataService.updateClaimData(claimData)
                .then(() => {
                    // Show confirmation
                    this.showSaveConfirmation = true;

                    // Hide after 3 seconds
                    setTimeout(() => {
                        this.showSaveConfirmation = false;
                    }, 5000);

                    // After successful save, refresh the data
                    this.$emit('refreshWizardAppData', true);
                })
                .catch(error => {
                    console.error('Error saving claim reserve:', error);
                    this.$toast.error('Failed to save claim reserve');
                });
        },

    },

    computed: {
        instructions: function () {
            return (
                "Edit the Assessment Details below as appropriate. You can also add/update various Claim Damages and Claim " +
                "Settlements as required in the corresponding sections below."
            );
        },
        formattedClaimReserve: {
            get() {
                // Format the number with thousands separators
                return this.appData.claim.K_ClaimReserve ?
                    Number(this.appData.claim.K_ClaimReserve).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }) : '';
            },
            set(value) {
                // Remove any non-numeric characters except decimal point
                const numericValue = value.replace(/[^0-9.]/g, '');
                // Convert to number and update the model
                this.appData.claim.K_ClaimReserve = numericValue ? Number(numericValue) : null;
            }
        }
    },
};
</script>
