<template>
    <ValidationObserver ref="claimWizard">
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Wizard-->
                <div class="wizard wizard-3" id="claim_wizard" data-wizard-state="step-first" data-wizard-clickable="true">
                    <div class="wizard-nav border-bottom mb-1 mb-lg-5">
                        <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                            <div v-for="(claimPage, index) in claimPages" :key="index" class="wizard-step"
                                style="cursor: default" data-wizard-type="step">
                                <div class="wizard-label">
                                    <h3 class="wizard-title">
                                        <span>{{ index + 1 }}</span>{{ claimPage.title }}
                                    </h3>
                                    <div class="wizard-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->

                    <!--begin: Wizard Body-->
                    <div class="card card-custom card-shadowless rounded-top-0">
                        <div class="card-body p-0">
                            <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                                <div class="col-xl-12 col-xxl-10">
                                    <!--begin: Wizard Form-->
                                    <div class="form" id="claim_wizard_form">
                                        <b-alert v-if="wizardAppData && isWizardReadOnly" variant="secondary" show>
                                            <div class="text-danger">
                                                <span v-if="wizardAppData.claim.K_DateClaimFinalised">
                                                    <i class="fas fa-lock fa-md"></i>
                                                    This claim was finalised on
                                                    {{
                                                        wizardAppData.claim.K_DateClaimFinalised
                                                        | dateParse("YYYY-MM-DD")
                                                        | dateFormat("DD/MM/YYYY")
                                                    }}
                                                    and cannot be modified.<br /><br />
                                                </span>
                                                <span v-if="!wizardAppData.claim.K_DateClaimFinalised">
                                                    This claim is in finalised status and cannot be
                                                    modified.<br /><br />
                                                </span>

                                                <span class="font-weight-bold" v-if="wizardAppData.claim.K_IndemnityStatus !== 'Accepted'
                                                    ">
                                                    Indemnity Status -
                                                    {{ this.wizardAppData.claim.K_IndemnityStatus }}<br />
                                                    Reason/Description -
                                                    {{
                                                        this.wizardAppData.claim
                                                            .K_IndemnityStatusDescription
                                                    }}
                                                </span>
                                            </div>
                                        </b-alert>

                                        <b-alert v-if="wizardAppData &&
                                                !isWizardReadOnly &&
                                                Array.isArray(wizardAppData.warnings) &&
                                                wizardAppData.warnings.length > 0
                                                " variant="secondary" show>
                                            <div class="text-danger font-weight-bold">
                                                <div v-for="(warning, index) in wizardAppData.warnings" :key="index">
                                                    Warning - <span v-html="warning"></span>
                                                </div>
                                            </div>
                                        </b-alert>

                                        <!-- ERROR ALERTS -->
                                        <b-alert v-if="errorAlert" variant="warning" show>
                                            <div class="text-dark" v-html="errorAlert"></div>
                                        </b-alert>

                                        <!--begin: Wizard Page 1-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <review-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly" @saveClaimAlert="saveClaimAlert"
                                                    @refreshWizardAppData="refreshWizardAppData"
                                                    @updateGlobalClaimStatus="updateGlobalClaimStatus"
                                                    @handleError="handleError" @refreshData="getWizardAppData" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 1-->

                                        <!--begin: Wizard Page 2-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <lodgement-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly" @saveClaimAlert="saveClaimAlert"
                                                    @refreshWizardAppData="refreshWizardAppData"
                                                    @updateGlobalClaimStatus="updateGlobalClaimStatus"
                                                    @saveWizardAppData="saveWizardAppData" @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 2-->

                                        <!--begin: Wizard Page 3-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <claim-mgmt-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly" @saveClaimAlert="saveClaimAlert"
                                                    @refreshWizardAppData="refreshWizardAppData"
                                                    @updateGlobalClaimStatus="updateGlobalClaimStatus"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 3-->

                                        <!--begin: Wizard Page 4 -->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <finalisation-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly" @saveClaimAlert="saveClaimAlert"
                                                    @refreshWizardAppData="refreshWizardAppData"
                                                    @updateGlobalClaimStatus="updateGlobalClaimStatus"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 4-->

                                        <!--begin: Wizard Actions -->
                                        <div class="d-flex justify-content-between border-top pt-10">
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                data-wizard-type="action-prev" v-show="wizardAppData">
                                                Previous Step
                                            </button>

                                            <button
                                                class="btn btn-outline-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isWizardReadOnly"
                                                @click.prevent="openIndemnityStatusDialog">
                                                Set Indemnity Status
                                            </button>

                                            <button class="btn btn-outline-danger font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && isWizardReadOnly" @click.prevent="reopenClaim">
                                                Re-open Claim
                                            </button>

                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isLastPage" @click.prevent="goToNextPage">
                                                Next Step
                                            </button>

                                            <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && isLastPage && !isWizardReadOnly
                                                    " :disabled="!wizardAppData ||
        !wizardAppData.statusFlags.claimReportSent
        " @click.prevent="finaliseClaim">
                                                Finalise Claim
                                            </button>
                                        </div>
                                        <!--end: Wizard Actions -->
                                    </div>
                                    <!--end: Wizard Form-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Body-->
                </div>
                <!--end: Wizard-->

                <b-modal ref="indemnityStatusDialog" size="lg" no-close-on-backdrop title="Set Indemnity Status"
                    @ok="setIndemnityStatus" @hidden="resetIndemnityStatusDetails">
                    <ValidationObserver ref="indemnityStatusData">
                        <div class="form-group row">
                            <label class="col-3 col-form-label text-right">Indemnity Status<span
                                    class="text-danger">*</span></label>
                            <div class="col-6">
                                <ValidationProvider rules="required" name="K_IndemnityStatus" v-slot="{ errors }">
                                    <b-form-select class="form-control" v-model="indemnityStatus"
                                        :disabled="isWizardReadOnly">
                                        <option :value="null">-- Select --</option>
                                        <option value="Accepted">Accepted</option>
                                        <option value="Partially Declined">
                                            Partially Declined
                                        </option>
                                        <option value="Declined - Further Action Required">
                                            Declined - Further Action Required
                                        </option>
                                        <option value="Declined - No Further Action">
                                            Declined - No Further Action
                                        </option>
                                        <option value="Withdrawn">Withdrawn</option>
                                    </b-form-select>
                                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-3 col-form-label text-right">Reason/Description<span
                                    class="text-danger">*</span></label>
                            <div class="col-6">
                                <ValidationProvider rules="required|max:2000" name="indemnityStatusDescription"
                                    v-slot="{ errors }">
                                    <b-textarea class="form-control min-rows" rows="4" v-model="indemnityStatusDescription"
                                        trim></b-textarea>
                                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                </b-modal>
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.page-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-disabled {
    cursor: not-allowed !important;
}
</style>

<script>
import _ from "lodash";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import ReviewPage from "../claim/pages/1-Review/ReviewPage.vue";
import LodgementPage from "../claim/pages/2-Lodgement/LodgementPage.vue";
import ClaimMgmtPage from "../claim/pages/3-ClaimMgmt/ClaimMgmtPage.vue";
import FinalisationPage from "../claim/pages/4-Finalisation/FinalisationPage.vue";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import Swal from "sweetalert2";

export default {
    name: "ClaimWizard",

    components: {
        ReviewPage,
        LodgementPage,
        FinalisationPage,
        ClaimMgmtPage,
    },

    data() {
        return {
            claimPages: [
                {
                    id: "wizardPage1",
                    title: "Review Claim",
                },
                {
                    id: "wizardPage2",
                    title: "Insurer Lodgement",
                },
                {
                    id: "wizardPage3",
                    title: "Claim Management",
                },
                {
                    id: "wizardPage4",
                    title: "Finalisation",
                },
            ],
            claimId: null,
            userId: null,
            wizardAppData: null,
            referenceData: null,
            wizardObj: null,
            currentStep: null,
            errorAlert: null,
            appDataString: null,
            indemnityStatus: null,
            indemnityStatusDescription: null,
        };
    },

    mounted() {
        // Show page loading
        this.startPageLoading();

        // Extract information from URL params
        let queryParams = this.$route.query;
        if (!queryParams.userId || !queryParams.claimId || !queryParams.token) {
            this.handleError("Unauthorised access!");
            return;
        }

        this.claimId = queryParams.claimId;
        this.userId = queryParams.userId;

        // Initialise ClaimDataService with the token
        this.$claimDataService.init(
            queryParams.claimId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise CommonDataService with the token
        this.$commonDataService.initClaim(
            queryParams.claimId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise the UI wizard
        this.initWizard();

        // Get Application Data
        this.getWizardAppData();
    },

    computed: {
        isWizardReadOnly: function () {
            let txnStatus = _.get(this.wizardAppData, "claim.K_Status");
            return txnStatus === "Claim Finalised";
        },

        isLastPage: function () {
            return this.wizardObj && this.wizardObj.isLastStep();
        },

        async saveClaimData(claimData) {
            try {
                await this.$claimDataService.updateClaimData(
                    this.claimId,
                    claimData,
                    this.userId
                );
            } catch (error) {
                this.handleError(error);
            }
        }
    },

    methods: {
        getReferenceData: function () {
            this.$claimDataService
                .getReferenceData()
                .then(({ data }) => {
                    this.referenceData = data.result;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        initWizard: function () {
            // Initialize form wizard
            const wizard = new KTWizard("claim_wizard", {
                startStep: 1, // Initial active step number
                clickableSteps: false, // Do not allow step clicking
            });

            this.wizardObj = wizard;
            this.currentStep = this.wizardObj.currentStep;

            // Before-Change event
            wizard.on("change", (/*wizardObj*/) => {
                this.clearError();
                // if (this.wizardAppData) {
                //   // Save app-data to server
                //   this.saveWizardAppData();
                // }
            });

            // Page-Changed event - Scroll to top of the page
            wizard.on("changed", (wizardObj) => {
                // Save current page number to server
                let currentStep = wizardObj.getStep();
                this.saveWizardCurrentPage(currentStep);

                // Last page - recalculate claim-cost
                // if (!this.isWizardReadOnly && currentStep === 4) {
                //   this.recalculateClaimCost();
                // }

                setTimeout(function () {
                    KTUtil.scrollTop();
                }, 500);
            });
        },

        isPageValid: async function (pageNumber) {
            let isValid = false;
            if (!this.$refs || !this.$refs.claimWizard || !this.wizardAppData) {
                return isValid;
            }

            let page = this.claimPages[pageNumber - 1];
            if (!page) {
                console.log(`Invalid page number ${pageNumber}`);
                return isValid;
            }
            let wizardPageId = page.id;
            let isDevelopment = process.env.NODE_ENV === "development";

            let childrenPages = this.$refs.claimWizard.$children;
            if (Array.isArray(childrenPages)) {
                let currentPageComponent = _.find(childrenPages, (child) => {
                    return child.$el && child.$el.id === wizardPageId;
                });
                if (!currentPageComponent) {
                    if (isDevelopment) {
                        console.log(
                            `Could not find currentPageComponent for id ${wizardPageId}`
                        );
                    }
                } else {
                    let currentPageRef = currentPageComponent.$refs[wizardPageId];
                    if (!currentPageRef) {
                        if (isDevelopment) {
                            console.log(
                                `Could not find currentPageRef for id ${wizardPageId}`
                            );
                        }
                    } else {
                        isValid = await currentPageRef.validate();
                        if (!isValid && isDevelopment) {
                            console.log(currentPageRef.errors);
                        }
                    }
                }
            }
            return isValid;
        },

        goToNextPage: async function () {
            this.clearError();
            let isNavAllowed = true;

            if (!this.isWizardReadOnly) {
                // Validate the current page
                isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
            }

            if (isNavAllowed) {
                // Go to next page
                this.wizardObj.goNext();
            }
        },

        saveWizardCurrentPage: function (pageNumber) {
            if (this.isWizardReadOnly) {
                return;
            }

            this.$commonDataService
                .saveWizardCurrentPage(pageNumber)
                .catch((error) => {
                    this.handleError(error);
                });
        },

        getWizardAppData: function () {
            this.startPageLoading();
            this.$claimDataService
                .getWizardAppData()
                .then(async ({ data }) => {
                    this.wizardAppData = data.result;
                    this.appDataString = stringify(this.wizardAppData);

                    console.log('wizardAppData', this.wizardAppData);

                    // set store with app data
                    this.$store.dispatch('setAppData', this.wizardAppData);

                    // Set broker company for logo
                    const brokerCompany = this.wizardAppData.claim?.K_BrokerCompany;
                    if (brokerCompany) {
                        this.$store.commit('setBrokerCompany', brokerCompany);
                    }

                    // Set wizard title and subtitle
                    this.setHeaderInformation();

                    // Update claim status on the header
                    this.updateGlobalClaimStatus();

                    // Update claim CRM link on the header
                    this.setClaimCrmLink();

                    // Update claim Box folder on the header
                    this.setClaimBoxLink();

                    // Set Claim Form Edit (Internal) Link
                    this.setClaimFormInternalLink();

                    // Get Reference Data
                    this.getReferenceData();

                    this.resetIndemnityStatusDetails();

                    // Set starting step for the wizard
                    let startStep = 1;
                    if (this.isWizardReadOnly) {
                        // If transaction is already completed, directly start the wizard on last page.
                        startStep = this.claimPages.length;
                    } else if (this.wizardAppData.claim) {
                        // Check if the user was previously on a particular wizard page
                        startStep = this.wizardAppData.claim.K_WizardCurrentPage || 1;
                    }

                    // Stop page loading
                    this.stopPageLoading();

                    if (startStep !== 1) {
                        setTimeout(() => {
                            this.wizardObj.goTo(startStep);
                        }, 0);
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setHeaderInformation: function () {
            if (this.wizardAppData) {
                // Set wizard title and subtitle in global store so that it can be shown on the header
                if (this.wizardAppData.claim) {
                    this.$store.commit("setWizardTitle", this.wizardAppData.claim.Name);

                    if (this.wizardAppData.asset) {
                        this.$store.commit(
                            "setWizardSubtitle",
                            this.wizardAppData.asset.Name
                        );
                    }
                }
            }
        },

        updateGlobalClaimStatus: function () {
            if (this.wizardAppData && this.wizardAppData.claim) {
                this.setHeaderInformation();

                let claim = this.wizardAppData.claim;
                let txnStatus = {
                    status: claim.K_Status,
                    nextStep: claim.K_NextStep,
                };

                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionStatus", txnStatus);
                this.$store.commit("setTransactionSubStatus", claim.K_SubStatus || "");
            }
        },

        refreshWizardAppData: function (showSpinner) {
            if (showSpinner) {
                this.startPageLoading();
            }
            this.$claimDataService
                .getWizardAppData()
                .then(({ data }) => {
                    this.wizardAppData = data.result;
                    this.appDataString = stringify(this.wizardAppData);

                    // Set wizard title and subtitle
                    this.setHeaderInformation();

                    // Update claim status on the header
                    this.updateGlobalClaimStatus();

                    this.resetIndemnityStatusDetails();

                    this.stopPageLoading();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        saveClaimAlert: function (claimAlert) {
            if (this.wizardAppData && this.wizardAppData.claim) {
                this.wizardAppData.claim.K_ClaimAlert = claimAlert;
                this.$claimDataService
                    .updateClaimData({ K_ClaimAlert: claimAlert })
                    .then((/*{ data }*/) => { })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },

        finaliseClaim: function () {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.startPageLoading();
            this.$claimDataService
                .finaliseClaim()
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();

                    this.$nextTick(() => {
                        Swal.fire({
                            title: "Claim Finalised",
                            html: `<p class="text-justify">This claim has been finalised.</p><br/>
                       <span class="font-weight-bold">Please close this browser window.</span>`,
                            icon: "success",
                            allowOutsideClick: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            showCancelButton: false,
                            showCloseButton: false,
                        });
                    });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setClaimBoxLink: function () {
            let claimBoxFolderId = _.get(
                this.wizardAppData,
                "claim.K_ClaimBoxFolderId"
            );
            if (claimBoxFolderId) {
                let txnBoxFolderLink = `https://collectiveib.app.box.com/folder/${claimBoxFolderId}`;

                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionBoxFolderLink", txnBoxFolderLink);
            }
        },

        setClaimFormInternalLink: function () {
            let claimFormInternalLink = _.get(
                this.wizardAppData,
                "claim.K_ClaimFormInternalLink"
            );
            if (claimFormInternalLink) {
                this.$store.commit("setClaimFormInternalLink", claimFormInternalLink);
            }
        },

        setClaimCrmLink: function () {
            let customModuleName = _.get(
                this.wizardAppData,
                "claim.K_CustomModuleName"
            );
            if (customModuleName) {
                let claimCrmLink = `https://crm.zoho.com.au/crm/tab/${customModuleName}/${this.claimId}`;

                // Set claim CRM link in global store so that it can be shown on the header
                this.$store.commit("setCrmRecordLink", claimCrmLink);
            }
        },

        saveWizardAppData: function (noSpinner) {
            if (this.isWizardReadOnly) {
                return;
            }

            // Save app-data to the server (only if it has changed)
            let currentAppDataString = stringify(this.wizardAppData);
            if (currentAppDataString !== this.appDataString) {
                if (!noSpinner) {
                    this.startPageLoading();
                }

                this.$claimDataService
                    .saveWizardAppData(this.wizardAppData)
                    .then(({ data }) => {
                        this.wizardAppData = data.result;
                        this.appDataString = stringify(this.wizardAppData);

                        if (!noSpinner) {
                            this.stopPageLoading();
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },

        openIndemnityStatusDialog: function () {
            this.$refs["indemnityStatusDialog"].show();
        },

        setIndemnityStatus: function (bvModalEvt) {
            bvModalEvt.preventDefault();

            this.$refs.indemnityStatusData.validate().then((isValid) => {
                if (isValid) {
                    this.startPageLoading();
                    this.$claimDataService
                        .setIndemnityStatus(
                            this.indemnityStatus,
                            this.indemnityStatusDescription
                        )
                        .then((/*{ data }*/) => {
                            this.$refs["indemnityStatusDialog"].hide();
                            // Refresh wizard-app-data
                            this.refreshWizardAppData();
                        })
                        .catch((error) => {
                            this.handleError(error);
                            this.$refs["indemnityStatusDialog"].hide();
                        });
                }
            });
        },

        resetIndemnityStatusDetails: function () {
            this.indemnityStatus = this.wizardAppData
                ? this.wizardAppData.claim.K_IndemnityStatus
                : null;
            this.indemnityStatusDescription = this.wizardAppData
                ? this.wizardAppData.claim.K_IndemnityStatusDescription
                : null;
        },

        reopenClaim: function () {
            Swal.fire({
                title: "Re-open Claim",
                text: "Are you sure you want to re-open this claim?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.startPageLoading();
                    this.$claimDataService
                        .reopenClaim()
                        .then((/*{ data }*/) => {
                            // Refresh wizard-app-data
                            this.refreshWizardAppData();
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                }
            });
        },

        recalculateClaimCost: function () {
            this.$claimDataService.calculateTotalClaimCost().catch((error) => {
                this.handleError(error);
            });
        },

        startPageLoading: function () {
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        },

        setError: function (message) {
            KTUtil.scrollTop();
            this.errorAlert = message;
        },

        clearError: function () {
            this.errorAlert = null;
        },

        stopPageLoading: function () {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },

        handleError: function (errorObj) {
            this.stopPageLoading();
            let errorMsg = null;
            const uiErrorPrefix = "UI_ERROR:";

            if (errorObj) {
                if (
                    typeof errorObj === "string" &&
                    errorObj.startsWith(uiErrorPrefix)
                ) {
                    errorMsg = errorObj.substring(uiErrorPrefix.length, errorObj.length);
                } else {
                    let responseStatus = _.get(errorObj, "response.status");
                    if (
                        responseStatus === 401 ||
                        (errorObj.message && errorObj.message.includes("status code 401"))
                    ) {
                        errorMsg =
                            "This session is invalid or expired. Please close this window.";
                    } else {
                        let responseResult = _.get(errorObj, "response.data.result");
                        if (_.isString(responseResult)) {
                            if (responseResult.startsWith(uiErrorPrefix)) {
                                errorMsg = responseResult.substring(
                                    uiErrorPrefix.length,
                                    responseResult.length
                                );
                            }
                        }
                    }
                }
            }
            if (!errorMsg) {
                errorMsg = "An unexpected error has occurred.";
            }

            this.setError(errorMsg);
            KTUtil.scrollTop();
        },
    },
};
</script>
